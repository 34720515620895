<template>
  <el-affix :offset="0">
    <Nav />
  </el-affix>
  <div class="w1900">
    <router-view></router-view>
    <Footer />
  </div>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
</template>

<script>
import Nav from './components/Nav.vue'
import Footer from './components/footer.vue'
export default {
  name: 'App',
  components: {
    Nav,Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
  margin: 0;
  padding: 0;
}
</style>
