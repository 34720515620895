import { createStore } from 'vuex';

export default createStore({
  state: {
    // game详情
    gameData: JSON.parse(sessionStorage.getItem("gameData"))
  },
  getters: {},
  mutations: {
    setGameData(state, value){
      state.gameData = value
      sessionStorage.setItem(
        'gameData',
        JSON.stringify(state.gameData)
      );
    }
  },
  actions: {},
  modules: {}
});
