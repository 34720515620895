<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.jpg')" />
    </div>
    <div class="auto_bg">
      <div class="w1400">
        <div class="account contetn">
          <div class="w900 contp">
            <div
              style="
                text-align: center;
                margin-bottom: 30px;
                font-size: 18px;
                font-weight: 700;
                color: red;
              "
            >
              Dear player: Now you can have more control over your data. We have
              provided two options below.
            </div>
            <div style="text-align: center; margin-bottom: 30px">
              1.{{ $t(`Account.line1`) }}
            </div>
            <div class="account-form">
              <el-form
                ref="form"
                :model="roleForm"
                :rules="rules"
                label-width="0"
                size="large"
              >
                <el-form-item prop="uid">
                  <el-input
                    v-model="roleForm.uid"
                    clearable
                    :placeholder="$t(`Account.form1`)"
                    style="width: 300px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="account">
                  <el-input
                    v-model="roleForm.account"
                    clearable
                    :placeholder="$t(`Account.form2`)"
                    style="width: 300px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="password" style="margin-bottom: 35px">
                  <el-input
                    v-model="roleForm.password"
                    clearable
                    show-password
                    :placeholder="$t(`Account.form3`)"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    style="margin-right: 30px"
                    type="danger"
                    @click="handleClick(1)"
                    >{{ $t(`Account.btn1`) }}</el-button
                  >
                  <el-button type="danger" @click="handleClick(2)">{{
                    $t(`Account.btn2`)
                  }}</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div style="text-align: center; margin-bottom: 30px">
              <span style="color: red">*</span>{{ $t(`Account.line2`) }}
            </div>
            <div style="text-align: center; margin-bottom: 30px">
              2.{{ $t(`Account.line3`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="tipVision" width="350px" center :show-close="false">
    <div class="text-item">
      <div>
        Dear users, before you officially start the next process of the
        cancellation of the game account, we will first make the following
        special statements for you: after the cancellation of the game account,
        your personal information in the game account will be deleted, and the
        related products and services that have been previously connected to the
        game account will no longer be connected, unless otherwise provided by
        laws and regulations or this Agreement. Once the game account is
        canceled, it cannot be restored. Please be careful.
      </div>
      <div>
        If you decide to cancel your game account after deliberation, please be
        sure to read carefully and fully understand this Game Account
        Cancellation Agreement (hereinafter referred to as “Agreement”) and
        agree to all the contents hereof, and this Agreement has contractual
        force. If you start the cancellation according to our cancellation
        process, or if you check this Agreement and start the next step of
        cancellation, you shall be deemed to have agreed to and complied with
        this Agreement in its entirety. To ensure the successful completion of
        the cancellation, please do not log in and use this game with this
        account during the cancellation process. Otherwise, you will be deemed
        to have canceled your application to cancel this account.
      </div>
      <div>
        You are welcome to register with other accounts and log in (available
        accounts are subject to specific ones provided by the game) if you hope
        to use our services again after the cancellation.
      </div>
      <div>
        1. You acknowledge and agree that upon cancellation of your game
        account, there will (may) be consequences, including but not limited to
        the following, which you are solely liable for:
      </div>
      <div>
        1.1 You will not be able to log in and use this game account, and your
        in-game friends will not be able to interact with you in this game;
      </div>
      <div>
        1.2 Unless otherwise provided by law, regulation or this Agreement, your
        personal information under this game account will be deleted or
        anonymized. You will not be able to retrieve your personal information,
        nor will you have the right to request us to retrieve the aforementioned
        personal information, including but not limited to: avatar, nickname,
        battle history, top-up and spending records, geographical location, chat
        history, etc.
      </div>
      <div>
        1.3 You are aware that when the game account is canceled, the in-game
        virtual items and other value-added services (hereinafter referred to as
        “game benefits” collectively for convenience) will also be deleted.
      </div>
      <div>
        1.4 Once the game account is canceled, it cannot be recovered. If you
        try to register again with the same account after cancellation, this
        account will be deemed as a new account;
      </div>
      <div>
        1.5 Once the game account is canceled, the User Agreement signed by and
        between you and us regarding the game will be terminated (except for
        those that have been agreed to continue to take effect). However, the
        termination of such Agreement and Terms of Service shall not affect the
        liabilities you should bear for using this game service before the
        cancellation of the game account, which you are still obliged to bear,
        including but not limited to the possible liabilities for breach of the
        contract mentioned above, damages and performance obligations;
      </div>
      <div>
        1.6 Other consequences (if any) arising from account cancellation.
      </div>
      <div>2. Miscellaneous</div>
      <div>
        2.1 This Agreement is an integral part of the User Agreement (the
        “Master Agreement”), and matters not covered herein shall be governed by
        the Master Agreement. In case of any inconsistency between this
        Agreement and the Master Agreement, this Agreement shall prevail. At the
        same time, you agree that the cancellation of your account shall not
        affect the liabilities you should bear for using this game service which
        you are still obliged to bear.
      </div>
      <div>
        When you cancel the account, data in this account will be deleted, and
        you will no longer be able to log into the account. Please be careful!
      </div>
    </div>
    <div class="btn-item">
      <el-button
        type="danger"
        round
        :disabled="btnDisable"
        @click="handleSubmit"
        >{{ $t(`Account.btn4`)
        }}<span style="margin-left: 5px" v-if="time != 0">{{
          time
        }}</span></el-button
      >
      <el-button round @click="handleClose">{{ $t(`Account.btn3`) }}</el-button>
    </div>
  </el-dialog>
</template>
  
  <script setup>
import { ref, getCurrentInstance } from "vue";
import md5 from "js-md5";
const { proxy } = getCurrentInstance();
const roleForm = ref({
  uid: "",
  account: "",
  password: "",
});
const rules = ref({
  uid: [
    {
      required: true,
      message: proxy.$t(`Account.form1`),
      trigger: "blur",
    },
  ],
  account: [
    {
      required: true,
      message: proxy.$t(`Account.form2`),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: proxy.$t(`Account.form3`),
      trigger: "blur",
    },
  ],
});
const type = ref(1);
const tipVision = ref(false);
const btnDisable = ref(true);
let timer = null;
const time = ref(10);

// 按钮
const handleClick = async (n) => {
  const validate = await proxy.$refs.form.validate().catch(() => {});
  if (!validate) {
    return false;
  }
  type.value = n;
  tipVision.value = true;
  btnDisable.value = true;
  time.value = 10;
  timer = setInterval(function () {
    if (time.value === 1) {
      btnDisable.value = false;
      clearInterval(timer);
    }
    time.value = time.value - 1;
  }, 1000);
};
const handleClose = () => {
  if (time.value != 0) {
    clearInterval(timer);
  }
  tipVision.value = false;
};
const handleSubmit = async () => {
  const form = {
    account: roleForm.value.account,
    password: roleForm.value.password,
    role_id: roleForm.value.uid,
    del_type: type.value,
    sign: md5("shop111111" + "8ff81c8707795c1bda6f1521cd8cde4D"),
  };
  const res = await proxy.$axios.post(
    `/v1/User/logoffUser?sign=${form.sign}&game_id=e869ae288d6d5f48&channel_id=googleplay`,
    form
  );
  if (res.code == 0) {
    handleClose();
    roleForm.value = {
      uid: "",
      account: "",
      password: "",
    };
    proxy.$message.success(res.message);
  } else {
    proxy.$message.error(res.message);
  }
};
</script>
  
  <style scoped>
/deep/.el-form-item__content {
  justify-content: center;
}
/deep/.el-form-item__error {
  left: 50%;
  margin-left: -150px;
}
.btn-item {
  display: flex;
  justify-content: center;
}
.text-item {
  height: 500px;
  overflow: auto;
}
</style>
  